<!--
 * @Description: 头部注释
 * @Author: v_Yangzhou
 * @Date: 2021-06-26 23:18:49
 * @LastEditors: v_binqin
 * @LastEditTime: 2021-06-29 20:30:35
-->
<!--  -->
<template>
  <div class="police-list">
    <van-list
      v-model="loading"
      :finished="finished"
      :error.sync="error"
      error-text="请求失败，点击重新加载"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-cell v-for="(item, index) in list" :key="index">
        <ul class="list-data">
          <li>
            <span class="key-value"> {{ item.createAt | formatDate }} </span>
          </li>
          <li>
            <label> 设备名称 </label>
            <span class="key-value">
              {{ item.deviceName }}
            </span>
            
          </li>
          <li>
            <label> 燃气阀操作 </label>
            <span class="key-value">
              {{ item.status == 1 ? '开启' : '关闭' }}
            </span>
          </li>
          <li>
            <label> 触发条件 </label>
            <span class="key-value">
              {{ item.action == 1 ? '手动' : '报警' }}
            </span>
            
          </li>
        </ul>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      error: false,
      page: 1,
    }
  },
  filters: {
    formatDate: function (value) {
      let date = new Date(value)
      let y = date.getFullYear()
      let MM = date.getMonth() + 1
      MM = MM < 10 ? '0' + MM : MM
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let m = date.getMinutes()
      m = m < 10 ? '0' + m : m
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s
    },
  },
  methods: {
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      this.http
        .get('/gas/handsLog', {
          params: {
            companyId: localStorage.getItem('companyId'),
            page: this.page,
            size: 20,
          },
        })
        .then(({ data }) => {
          if (data.code == 200) {
            this.loading = false
            this.list = this.list.concat(data.data.list)
            if (this.page == data.data.lastPage || !data.data.hasNextPage) {
              this.finished = true
            }
            this.page++
          } else {
            this.loading = false
            this.error = true
          }
        })
        .catch((err) => {
          this.loading = false
          this.error = true
        })
    },
  },
  created() {},
  mounted() {},
}
</script>
<style lang='less' scoped>
.list-data {
  li {
    position: relative;
    label {
      display: inline-block;
      width: 180px;
    }
    .key-value {
      font-weight: 600;
    }
    .time {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
</style>